// import slider images
import Avatar1 from '../src/assets/img/avt1.png';
import Avatar2 from '../src/assets/img/avt2.png';
import Avatar3 from '../src/assets/img/avt3.png';

export const slider = [
  {
    message:
      'I love the idea for the mascots/nft/game/marketplace concept.  Truly original and inspiring!',
    image: Avatar1,
    name: 'Laurie Howell',
    email: '',
  },
  {
    message:
      'I have used generative ai since its inception, and this is by far the most enjoyable.',
    image: Avatar2,
    name: 'Christie Ernser',
    email: '',
  },
  {
    message:
      'I love designing my own clothes and products, so when I saw this it was a no-brainer.',
    image: Avatar3,
    name: 'Frankie Collins',
    email: '',
  },
  {
    message:
      'I love the idea for the mascots/nft/game/marketplace concept.  Truly original and inspiring!',
    image: Avatar1,
    name: 'Laurie Howell',
    email: '',
  },
  {
    message:
      'I have used generative ai since its inception, and this is by far the most enjoyable.',
    image: Avatar2,
    name: 'Christie Ernser',
    email: '',
  },
  {
    message:
      'I love designing my own clothes and products, so when I saw this it was a no-brainer.',
    image: Avatar3,
    name: 'Frankie Collins',
    email: '',
  },
];
