
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Explore = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_92u9kd6', 'template_ingtn6r', form.current, '5SPGXXm_uChQ6iSAo')
      .then((result) => {
          console.log(result.text);
          alert("Awesome! We will get a reply to you soon!");
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <section className="min-h-[400px] mb-16 lg:mb-4" id="explore">
      <div className="container mx-auto h-full">
        <div className="h-full bg-explore bg-no-repeat bg-cover p-14 flex flex-col items-start justify-center">
          <h3
            className="text-3xl font-semibold mb-8"
            data-aos="fade-right"
            data-aos-offset="1000"
          >
            Explore Products in a new way
          </h3>
          <p
            className="max-w-xs mb-12"
            data-aos="fade-right"
            data-aos-offset="1100"
          >
            Drop us a message!
          </p>
         <p
            className="max-w-xs mb-12"
            data-aos="fade-right"
            data-aos-offset="1100">
          We would love to here from you!</p>
        
          <form
            ref={form} 
            onSubmit={sendEmail}
            className="flex flex-col w-full space-y-4 gap-x-4 lg:flex-row lg:space-y-0"
            data-aos="fade-up"
            data-aos-offset="200"
          >

            
            <input
            className="bg-gradient-to-t from-[#341D38] to-[#271223] h-12 px-4 outline-none rounded-md"
            type="text" 
            placeholder="Your name"
            name="user_name" />
            <input
              className="bg-gradient-to-t from-[#341D38] to-[#271223] h-12 px-4 outline-none rounded-md"
              type="email"
              placeholder="Your email"
              name="user_email"      
              
            />              
              <input 
              className="bg-gradient-to-t from-[#341D38] to-[#271223] h-12 px-4 outline-none rounded-md"
              name="message" 
              placeholder="Leave a message"
              type="text"
             
              
              />
            <button className="btn" type="submit" value="send">Send</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Explore;
