import Img1 from "../assets/img/shirt.png";
import Img2 from "../assets/img/pants.png";

const Experience = () => {
  return (
    <section className="mb-12 lg:mb-24" id="experience">
      <div className="container mx-auto">
        <div className="flex flex-col min-h-[480px] lg:space-x-20 lg:flex-row">
          <div className="flex-1 flex space-x-6 items-center lg:space-x-12">
            {/* images */}
            <div
              className="self-start"
              data-aos="fade-down"
              data-aos-offset="400"
            >
              <img src={Img1} alt="" />
            </div>
            <div className="self-end" data-aos="fade-up">
              <img src={Img2} alt="" />
            </div>
          </div>
          {/* text  */}
          <div
            className="flex-1 flex flex-col items-start justify-center mt-6 lg:mt-0"
            data-aos="fade-left"
            data-aos-offset="400"
          >
            <h2 className="text-3xl font-bold mb-6">
              Create custom art and products.
            </h2>
            <p className="font-secondary mb-6">
              Use our generative AI models or bring your own.
            </p>
            <button className="btn" href="https://app.bhuvahai.com">Join Beta</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
