import { IoClose } from "react-icons/io5";

const NavMobile = ({ setNavMobile }) => {
  const handleItemClick = () => {
    setNavMobile(false);
  };

  return (
    <nav className="lg:hidden bg-[#251f3f] w-full h-full">
      <IoClose
        onClick={() => setNavMobile(false)}
        className="text-3xl absolute left-4 top-6 cursor-pointer"
      />
      <ul className="flex flex-col items-center justify-center space-y-8 h-full font-secondary">
        <li className="text-lg">
          <a href="#experience" onClick={handleItemClick}>Home</a>
        </li>
        <li className="text-lg">
          <a href="#explore" onClick={handleItemClick}>Explore</a>
        </li>
        <li className="text-lg">
          <a href="#testimonials" onClick={handleItemClick}>Users</a>
        </li>
        <li className="btn">
          <a href="https://app.bhuvahai.com" onClick={handleItemClick}>Join Beta</a>
        </li>
      </ul>
    </nav>
  );
};

export default NavMobile;





/*

import { IoClose } from "react-icons/io5";




const NavMobile = ({ setNavMobile }) => {
  return (
    <nav className="lg:hidden bg-[#251f3f] w-full h-full">
      <IoClose
        onClick={() => setNavMobile(false)}
        className="text-3xl absolute left-4 top-6 cursor-pointer"
      />
      <ul className="flex flex-col items-center justify-center space-y-8 h-full font-secondary">
        <li className="text-lg">
          <a href="#experience">Home</a>
        </li>
        <li className="text-lg">
          <a href="#explore">Explore</a>
        </li>
        <li className="text-lg">
          <a href="#testimonials">Users</a>
        </li>
        <li className="btn">
          <a href="https://app.bhuvahai.com">Join Beta</a>
        </li>
      </ul>
    </nav>
  );
};

export default NavMobile;

*/
