const Nav = () => {
  return (
    <nav className="hidden lg:flex">
      <ul className="flex items-center space-x-12 font-secondary">
        <li className="nav-link">
          <a href="#experience">Home</a>
        </li>
        <li className="nav-link">
          <a href="#explore">Explore</a>
        </li>
        <li className="nav-link">
          <a href="#testimonials">Users</a>
        </li>
        <li className="btn">
          <a href="https://app.bhuvahai.com">Join Beta</a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
